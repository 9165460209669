/* GET */
export const delivering = (state = {
  isFetching: false,
  items: []
}, action) => {
  switch (action.type) {
    case 'delivery / Request':
      return {
        ...state,
        isFetching: true,
        lastUpdated: action.receivedAt
      }
    case 'delivery / Receive':
      return {
        isFetching: false,
        items: action.response.data,
        lastUpdated: action.receivedAt
      }
    case 'delivery / Save':
      return {
        isFetching: false,
        items: action.response.data,
        lastUpdated: action.receivedAt
      }
    case 'delivery / Reset':
      return {
        isFetching: false,
        items: [],
        lastUpdated: action.receivedAt
      }
    case 'delivery / ChangeStatus':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, status: action.status } : todo)
      }
    case 'delivery / ChangeDone':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, done: action.status } : todo)
      }
    case 'delivery / ChangeType':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, type: action.status } : todo)
      }
    case 'delivery / ChangeNot':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, not: action.status } : todo)
      }
    case 'delivery / ChangePhone':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, clickPhone: new Date() } : todo)
      }
    case 'delivery / ChangeWhatsapp':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, clickWhatsapp: new Date() } : todo)
      }
    case 'delivery / ChangeMaps':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, clickMaps: new Date() } : todo)
      }
    case 'delivery / ChangeTradeSts':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, tradeStatus: action.status } : todo)
      }
    case 'delivery / ChangeTradeJS':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, tradeJs: action.status } : todo)
      }
    case 'delivery / ChangeTradeDate':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, tradeDate: new Date() } : todo)
      }
    case 'delivery / ChangePayType':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, payType: action.status } : todo)
      }
    case 'delivery / ChangePayJS':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, payJs: action.status } : todo)
      }
    case 'delivery / ChangePayDate':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, payDate: new Date() } : todo)
      }
    case 'delivery / ChangePhoto':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, photo: action.status } : todo)
      }
    case 'delivery / ChangePhotoDate':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, photoDate: new Date() } : todo)
      }
    case 'delivery / ChangeStop':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, stop: action.status } : todo)
      }
    case 'delivery / ChangePayMoney':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, payMoney: action.status } : todo)
      }
    case 'delivery / ChangeShift':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, productShift: action.status } : todo)
      }
    default:
      return state
  }
}
