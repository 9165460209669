import actions from './actions'

const getConferences = actions.getConferences
const productChangeStatus = actions.productChangeStatus
const postConferences = actions.postConferences
const postDelivery = actions.postDelivery
const productChangeType = actions.productChangeType
const productChangeDate = actions.productChangeDate
const deliveryChangeStatus = actions.deliveryChangeStatus
const deliveryChangeDone = actions.deliveryChangeDone
const deliveryChangeType = actions.deliveryChangeType
const deliveryChangeNot = actions.deliveryChangeNot
const deliveryChangePhone = actions.deliveryChangePhone
const deliveryChangeWhatsapp = actions.deliveryChangeWhatsapp
const deliveryChangeMaps = actions.deliveryChangeMaps
const deliveryChangeTradeSts = actions.deliveryChangeTradeSts
const deliveryChangeTradeJS = actions.deliveryChangeTradeJS
const deliveryChangeTradeDate = actions.deliveryChangeTradeDate
const deliveryChangePayType = actions.deliveryChangePayType
const deliveryChangePayJS = actions.deliveryChangePayJS
const deliveryChangePayDate = actions.deliveryChangePayDate
const deliveryChangePhoto = actions.deliveryChangePhoto
const deliveryChangePhotoDate = actions.deliveryChangePhotoDate
const deliveryChangeStop = actions.deliveryChangeStop
const getDelivery = actions.getDelivery
const productChangeTrackStatus = actions.productChangeTrackStatus
const productChangeTrackType = actions.productChangeTrackType
const productChangeTrackErr = actions.productChangeTrackErr
const productChangeTrackDate = actions.productChangeTrackDate
const productChangeConclude = actions.productChangeConclude
const deliveryChangePayMoney = actions.deliveryChangePayMoney
const deliveryChangeShift = actions.deliveryChangeShift

export default {
  getConferences,
  productChangeStatus,
  postConferences,
  postDelivery,
  productChangeDate,
  productChangeType,
  deliveryChangeStatus,
  deliveryChangeDone,
  deliveryChangeType,
  deliveryChangeNot,
  deliveryChangePhone,
  deliveryChangeWhatsapp,
  deliveryChangeMaps,
  deliveryChangeTradeSts,
  deliveryChangeTradeJS,
  deliveryChangeTradeDate,
  deliveryChangePayType,
  deliveryChangePayJS,
  deliveryChangePayDate,
  deliveryChangePhoto,
  deliveryChangePhotoDate,
  deliveryChangeStop,
  getDelivery,
  productChangeTrackStatus,
  productChangeTrackType,
  productChangeTrackErr,
  productChangeTrackDate,
  deliveryChangePayMoney,
  deliveryChangeShift,
  productChangeConclude
}
