import api from '../../../../utils/api'

/* GET */
export const requestAdministrators = () => ({
  type: 'administrators / Request',
  receivedAt: Date.now()
})

export const receiveAdministrators = (response) => ({
  type: 'administrators / Receive',
  response,
  receivedAt: Date.now()
})

export const resetAdministrators = () => ({
  type: 'administrators / Reset',
  receivedAt: Date.now()
})

export const getAdministrators = (e, usr) => dispatch => {
  dispatch(requestAdministrators())
  api.getAdministrators(e, usr, false).then((response) => {
    if (response && response.success && response.data && response.data) {
      dispatch(receiveAdministrators(response))
    } else {
      dispatch(resetAdministrators())
    }
  }).catch((e) => {
    dispatch(resetAdministrators())
  })
}
