/* GET */
export const administrators = (state = {
  isFetching: false,
  items: []
}, action) => {
  switch (action.type) {
    case 'administrators / Request':
      return {
        ...state,
        isFetching: true,
        lastUpdated: action.receivedAt
      }
    case 'administrators / Receive':
      return {
        isFetching: false,
        items: action.response.data,
        lastUpdated: action.receivedAt
      }
    case 'administrators / Reset':
      return {
        isFetching: false,
        items: [],
        lastUpdated: action.receivedAt
      }
    default:
      return state
  }
}
