import apiAsync from './api-async'

class Api {
  postLogin (obj, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'login',
      method: 'POST',
      obj: obj,
      showLoading: showLoading
    })
  }

  getAdministrators (vehicle, user, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'vehicle',
      method: 'GET',
      path: '/' + vehicle + '/' + user,
      showLoading: showLoading
    })
  }

  getConference (vehicle, user, place, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'products',
      method: 'GET',
      path: '/' + vehicle + '/' + user + '/' + place,
      showLoading: showLoading
    })
  }

  getDeliveryApi (vehicle, user, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'deliverys',
      method: 'GET',
      path: '/' + vehicle + '/' + user,
      showLoading: showLoading
    })
  }

  postConference (obj, showLoading) {
    return apiAsync.requestSync({
      endpoint: 'conferences',
      method: 'POST',
      obj: obj,
      showLoading: showLoading
    })
  }

  postDelivery (road, obj, id, sts, user, showLoading) {
    if (road !== '') {
      road = '/' + road
    }

    return apiAsync.requestSync({
      endpoint: 'deliverys',
      method: 'POST',
      path: road + '/' + id + '/' + sts + '/' + user,
      obj: obj,
      showLoading: showLoading
    })
  }
}

export default (new Api())
