import React, { Suspense } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer, toast } from 'react-toastify'

import '../../asset/css/fonts.css'
import '../../asset/css/default.css'
import '../../asset/css/toastify.css'

import SuspenseFallback from './SuspenseFallback'

// code-splitting
const Loading = React.lazy(() => import('./Loading'))
const AuthenticationRenewal = React.lazy(() =>
  import('./AuthenticationRenewal')
)
const RedirectToLogin = React.lazy(() => import('./RedirectToLogin'))
const Router = React.lazy(() => import('./Router'))
const NetworkDetector = React.lazy(() => import('./NetworkDetector'))
const EnvironmentWarning = React.lazy(() => import('./EnvironmentWarning'))

const ServiceWorkerWrapper = React.lazy(() =>
  import('./ServiceWorkerBox/ServiceWorkerWrapper')
)

export class HelloTudoBonus extends React.Component {
  render () {
    return (
      <Suspense fallback={<SuspenseFallback message='Loading...' />}>
        <Loading active={this.props.isLoading}>
          <Suspense
            fallback={<SuspenseFallback message='Authentication renewal...' />}
          >
            <AuthenticationRenewal />
          </Suspense>

          <Suspense fallback={<SuspenseFallback message='Loading...' />}>
            <RedirectToLogin />
          </Suspense>

          <Suspense fallback={<SuspenseFallback message='Loading service worker...' />}>
            <ServiceWorkerWrapper />
          </Suspense>

          <Suspense
            fallback={
              <SuspenseFallback message='Loading environment warning...' />
            }
          >
            <EnvironmentWarning />
          </Suspense>

          <Suspense
            fallback={
              <SuspenseFallback message='Loading network detector...' />
            }
          >
            <NetworkDetector />
          </Suspense>

          <Suspense fallback={<SuspenseFallback message='Loading router...' />}>
            <Router username={this.props.username} />
          </Suspense>

          <ToastContainer
            position={toast.POSITION.BOTTOM_CENTER}
            pauseOnFocusLoss={false}
            pauseOnHover={false}
          />
        </Loading>
      </Suspense>
    )
  }
}

const mapStateToProps = ({ api }) => {
  return {
    isLoading: api && api.isPending
  }
}

export default withRouter(connect(mapStateToProps)(HelloTudoBonus))
