import { combineReducers } from 'redux'

import { conferences } from './reducers/conferences'
import { delivering } from './reducers/delivering'

const conferenceReducer = combineReducers({
  conferences,
  delivering
})

export default conferenceReducer
