import api from '../../../utils/api'
import * as constants from '../../shared/Constants'

/**
 * Thunk action creators
 */
export const loginSucceeded = (email, data) => {
  return {
    type: 'login/SUCCEEDED',
    data: {
      username: data.user_name || email,
      expiration_date: data.expiration_date,
      token: data.token,
      userid: data.user_id || 1,
      board: data.board || 'ABC123',
      board_day: data.board_day || 'ABC123,AAA123'
    }
  }
}

export const loginVehicle = (vlr) => {
  return {
    type: 'login/VEHICLE',
    new: vlr
  }
}
export const loginStat = (vlr) => {
  return {
    type: 'login/STATUS',
    new: vlr || true
  }
}

export const loginsendErrClear = (count) => {
  return {
    type: 'login/SENDCLEAR',
    count: count
  }
}
export const loginsendErrClearAll = () => {
  return {
    type: 'login/SENDCLEARALL'
  }
}

export const loginsendErr = (count, path, obj, id, sts, user) => {
  return {
    type: 'login/SEND',
    count: count,
    path: path,
    obj: obj,
    id: id,
    sts: sts,
    user: user
  }
}

export const loginFailed = () => {
  return {
    type: 'login/FAILED'
  }
}

export const systemReload = () => (dispatch) => {
  dispatch({ type: 'redux_store/CLEAR' })
}

const formatMessageLogin = (message) => {
  if (constants.LOGIN_ERROR[message]) {
    return constants.LOGIN_ERROR[message]
  }
  if (
    constants.LOGIN_ERROR[message] &&
    constants.LOGIN_ERROR[message] === constants.LOGIN_ERROR.FORCE_RELOAD
  ) {
    window.location.reload()
  }
  return constants.LOGIN_ERROR_GENERIC
}

export const login = (credentials, onSuccess, onError) => (dispatch) => {
  api.postLogin(credentials, true).then((response) => {
    response = response || {}
    if (response.success && response.data) {
      dispatch(loginSucceeded(credentials.email, response.data))
      onSuccess(response)
    } else if (response.success === false) {
      dispatch(loginFailed())
      onError(formatMessageLogin(response.message))
    } else {
      dispatch(loginFailed())
      onError(constants.LOGIN_ERROR_SYSTEM_UNAVAILABLE)
    }
  })
}

/**
 * Reducer
 */
export default function authentication (state = {}, action) {
  switch (action.type) {
    case 'login/SUCCEEDED':
      return action.data
    case 'login/VEHICLE':
      return { ...state, newVehicle: action.new }
    case 'login/STATUS':
      return { ...state, connection: action.new }
    case 'login/SENDCLEAR':
      return { ...state, err: state.err.filter(todo => todo.count !== action.count) }
    case 'login/SENDCLEARALL':
      return { ...state, err: [] }
    case 'login/SEND':
      return {
        ...state,
        err: [...state.err || '',
          {
            count: action.count || '',
            path: action.path || '',
            obj: action.obj || '',
            id: action.id || '',
            sts: action.sts || '',
            user: action.user || ''
          }]
      }
    case 'login/FAILED':
      return {}
    case 'login/TOKEN_EXPIRED':
      return {}
    case 'login/LOGOUT':
      return {}
    default:
      return state
  }
}
