/**
 * Reducer
 */
export default function api (
  state = { requests: [], isPending: false },
  action
) {
  switch (action.type) {
    case 'api/REQUESTED': {
      const newState = { ...state }
      newState.requests.push(action.data.requestUuid)
      newState.isPending = true
      return newState
    }
    case 'api/RECEIVED': {
      const requests = state.requests.filter((i) => {
        return i !== action.data.requestUuid
      })
      return {
        ...state,
        requests: requests,
        isPending: requests.length > 0
      }
    }
    case 'login/TOKEN_EXPIRED':
      return {
        requests: [],
        isPending: false
      }
    default:
      return state
  }
}
