import api from '../../../../utils/api'

/* GET */
export const requestConferences = () => ({
  type: 'conferences / Request',
  receivedAt: Date.now()
})

export const receiveConferences = (response) => ({
  type: 'conferences / Receive',
  response,
  receivedAt: Date.now()
})

export const receiveConferencesSave = (response) => ({
  type: 'conferences / Save',
  response,
  receivedAt: Date.now()
})

export const resetConferences = () => ({
  type: 'conferences / Reset',
  receivedAt: Date.now()
})

/* GET */
export const requestDelivery = () => ({
  type: 'delivery / Request',
  receivedAt: Date.now()
})

export const receiveDelivery = (response) => ({
  type: 'delivery / Receive',
  response,
  receivedAt: Date.now()
})

export const receiveDeliverySave = (response) => ({
  type: 'delivery / Save',
  response,
  receivedAt: Date.now()
})

export const resetDelivery = () => ({
  type: 'delivery / Reset',
  receivedAt: Date.now()
})

export const productChangeStatus = (id, status) => ({
  type: 'product / ChangeStatus',
  id,
  status
})

export const productChangeDate = (id) => ({
  type: 'product / ChangeDate',
  id
})

export const productChangeType = (id, status) => ({
  type: 'product / ChangeType',
  id,
  status
})

export const deliveryChangeStatus = (id, status) => ({
  type: 'delivery / ChangeStatus',
  id,
  status
})

export const deliveryChangeDone = (id, status) => ({
  type: 'delivery / ChangeDone',
  id,
  status
})

export const deliveryChangeType = (id, status) => ({
  type: 'delivery / ChangeType',
  id,
  status
})

export const deliveryChangeNot = (id, status) => ({
  type: 'delivery / ChangeNot',
  id,
  status
})

export const deliveryChangePhone = (id) => ({
  type: 'delivery / ChangePhone',
  id
})

export const deliveryChangeWhatsapp = (id) => ({
  type: 'delivery / ChangeWhatsapp',
  id
})

export const deliveryChangeMaps = (id) => ({
  type: 'delivery / ChangeMaps',
  id
})

export const deliveryChangeTradeSts = (id, status) => ({
  type: 'delivery / ChangeTradeSts',
  id,
  status
})

export const deliveryChangeTradeJS = (id, status) => ({
  type: 'delivery / ChangeTradeSts',
  id,
  status
})

export const deliveryChangeTradeDate = (id) => ({
  type: 'delivery / ChangeTradeDate',
  id
})

export const deliveryChangePayType = (id, status) => ({
  type: 'delivery / ChangePayType',
  id,
  status
})

export const deliveryChangePayJS = (id, status) => ({
  type: 'delivery / ChangePayJS',
  id,
  status
})

export const deliveryChangePayDate = (id) => ({
  type: 'delivery / ChangePayDate',
  id
})

export const deliveryChangePhoto = (id, status) => ({
  type: 'delivery / ChangePhoto',
  id,
  status
})

export const deliveryChangePhotoDate = (id) => ({
  type: 'delivery / ChangePhotoDate',
  id
})

export const deliveryChangeStop = (id, status) => ({
  type: 'delivery / ChangeStop',
  id,
  status
})

export const productChangeTrackStatus = (id, status) => ({
  type: 'product / ChangeTrackStatus',
  id,
  status
})
export const productChangeTrackType = (id, status) => ({
  type: 'product / ChangeTrackType',
  id,
  status
})
export const productChangeTrackErr = (id, status) => ({
  type: 'product / ChangeTrackErr',
  id,
  status
})
export const productChangeConclude = (id) => ({
  type: 'product / ChangeConclude',
  id
})
export const deliveryChangePayMoney = (id, status) => ({
  type: 'delivery / ChangePayMoney',
  id,
  status
})
export const deliveryChangeShift = (id, status) => ({
  type: 'delivery / ChangeShift',
  id,
  status
})
export const productChangeTrackDate = (id) => ({
  type: 'product / ChangeTrackDate',
  id
})
export const getDelivery = (vehicle, user) => dispatch => {
  dispatch(requestDelivery())
  api.getDeliveryApi(vehicle, user, false).then((response) => {
    if (response && response.success && response.data) {
      dispatch(receiveDelivery(response))
    } else {
      dispatch(resetDelivery())
    }
  }).catch((e) => {
    dispatch(resetDelivery())
  })
}

export const getConferences = (vehicle, user, place) => dispatch => {
  dispatch(requestConferences())
  api.getConference(vehicle, user, place, false).then((response) => {
    if (response && response.success && response.data) {
      dispatch(receiveConferences(response))
    } else {
      dispatch(resetConferences())
    }
  }).catch((e) => {
    dispatch(resetConferences())
  })
}

export const postConferences = (obj) => dispatch => {
  dispatch(requestConferences())
  api.postConference(obj, false).then((response) => {
    if (response && response.success && response.data) {
      dispatch(receiveConferencesSave(response))
    }
  }).catch((e) => {
    console.log('')
  })
}

export const postDelivery = (path, obj, id, sts, user) => dispatch => {
  dispatch(requestDelivery())
  api.postDelivery(path, obj, id, sts, user, false).then((response) => {
    if (response && response.success && response.data) {
      dispatch(receiveDeliverySave(response))
    }
  }).catch((e) => {
    console.log('')
  })
}
