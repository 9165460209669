import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import TagManager from 'react-gtm-module'
import { createBrowserHistory as createHistory } from 'history'
import configureStore from './state/store'
import HelloTudoBonus from './modules/shared/HelloTudoBonus'
import apiAsync from './utils/api-async'
import { configStatus } from './utils/configStatus'

// Performance Monitoring
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const history = createHistory()

if (window.waEnv === 'production') {
  // Performance Monitoring
  Sentry.init({
    dsn: window.sentryDns,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    tracesSampleRate: 1.0
  })

  const tagManagerArgs = {
    gtmId: window.gtmId
  }
  TagManager.initialize(tagManagerArgs)
}

// initial state
const reduxStoreAndPersistor = configureStore(window.REDUX_INITIAL_DATA)

configStatus(window.msApi)
apiAsync.setConfig(window.msApi)
apiAsync.setDispatch(reduxStoreAndPersistor.store.dispatch)

// garbage-collected
delete window.REDUX_INITIAL_DATA

ReactDOM.render(
  <ReduxProvider store={reduxStoreAndPersistor.store}>
    <PersistGate loading={null} persistor={reduxStoreAndPersistor.persistor}>
      <Router history={history}>
        <HelloTudoBonus />
      </Router>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('root')
)
