export const configStatus = (msApi) => {
  // ConfigMap x ConfigStatus
  msUrls.map(msUrl => {
    const status = msApi[msUrl]
    if (status === undefined || status === '') {
      window.alert('Variaveis de ambiente não configuradas')
      console.log('Url: ' + msUrl + ' não configurada')
    }
    return true
  })

  // ConfigStatus x ConfigMap
  if (window.waEnv === 'dev') {
    const urlConfigs = Object.keys(msApi)
    urlConfigs.map(configUrl => {
      const status = msUrls.find(i => i === configUrl)
      if (status === undefined || status === '') {
        window.alert('Variaveis de ambiente não configuradas')
        console.log('Url: ' + configUrl + ' não configurada')
      }
      return true
    })
  }
}

const msUrls = [
  'login',
  'administrators',
  'conferences',
  'products',
  'vehicle',
  'deliverys'
]
