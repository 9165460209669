/* GET */
export const conferences = (state = {
  isFetching: false,
  items: []
}, action) => {
  switch (action.type) {
    case 'conferences / Request':
      return {
        ...state,
        isFetching: true,
        lastUpdated: action.receivedAt
      }
    case 'conferences / Receive':
      return {
        isFetching: false,
        items: action.response.data,
        lastUpdated: action.receivedAt
      }
    case 'conferences / Save':
      return {
        isFetching: false,
        items: action.response.data,
        lastUpdated: action.receivedAt
      }
    case 'conferences / Reset':
      return {
        isFetching: false,
        items: [],
        lastUpdated: action.receivedAt
      }
    case 'product / ChangeStatus':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, status: action.status } : todo)
      }
    case 'product / ChangeDate':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, trackDate: new Date() } : todo)
      }
    case 'product / ChangeConclude':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, concludeDate: new Date() } : todo)
      }
    case 'product / ChangeType':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, type: action.status } : todo)
      }
    case 'product / ChangeTrackStatus':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, trackStatus: action.status } : todo)
      }
    case 'product / ChangeTrackType':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, trackType: action.status } : todo)
      }
    case 'product / ChangeTrackErr':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, trackErr: action.status } : todo)
      }
    case 'product / ChangeTrackDate':
      return {
        isFetching: false,
        items: state.items.map(todo => todo.id === action.id ? { ...todo, trackDate: action.status } : todo)
      }

    default:
      return state
  }
}
