/* Form Validation */
export const MINIMUM_CHARACTER = 'Mínimo de caracteres'
export const MAXIMUM_CHARACTER = 'Máximo de caracteres'
export const REQUIRED = 'Campo obrigatório'
export const REQUIRED_CONDITIONALLY = 'Ao menos um campo obrigatório'

/* System Strings */
export const STR_SYSTEM_TITLE = 'TudoBônus Interaction'
export const STR_SYSTEM_RELOAD = 'Reiniciar sistema'
export const RESPONSE_MESSAGE_DEFAULT = 'Não foi possível executar esse comando.'
export const STR_COPY_TO_CLIPBOARD = 'Copiado!'
export const STR_MESSAGE_CONFIRM_DELETE = 'Deseja deletar esse registro?'
export const STR_COMMAND_SENDING = 'Enviando comando...'
export const STR_COMMAND_UPDATE = 'Atualizando registro...'
export const STR_COMMAND_SENT_SUCCESSFULLY = 'Comando enviado com sucesso'
export const STR_DATA_UPDATED_WITH_THE_LATEST_VERSION = 'Registro atualizado com a última versão'

/* Login */
export const LOGIN_ERROR_GENERIC = 'Credenciais inválidas.'
export const LOGIN_ERROR_SYSTEM_UNAVAILABLE = 'Sistema indisponível.'
export const LOGIN_ERROR = {
  'Authentication failure.': LOGIN_ERROR_GENERIC,
  'Email and password expected.': LOGIN_ERROR_GENERIC,
  'Ticket not found': LOGIN_ERROR_GENERIC,
  FORCE_RELOAD: true
}
