import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose
} from 'redux'
import { createMigrate, persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import * as reducers from './ducks'
import api from '../utils/api.js'
import { migrations } from './migrations'

const persistConfig = {
  key: 'root',
  storage,
  version: 0,
  blacklist: ['api'],
  migrate: createMigrate(migrations, { debug: false })
}

const configureStore = (initialState = {}) => {
  const rootReducer = (state, action) => {
    if (action.type === 'redux_store/CLEAR') {
      // resets the current state
      const newState = appReducer(undefined, action)
      // and preserves authentication
      newState.authentication = reducers.authentication(state.authentication, action)
      return newState
    }
    return appReducer(state, action)
  }
  const appReducer = combineReducers(reducers)
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
    compose

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(thunk.withExtraArgument(api))
    )
  )
  const persistor = persistStore(store)

  return { store, persistor }
}

export default configureStore
